var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container px-0"
  }, [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('SwitchBox', {
    staticClass: "col-12 col-md-4 col-sm-6",
    attrs: {
      "driver": "swiatlo",
      "pin": "49",
      "history-key": "w1_49",
      "reversed": ""
    }
  }), _c('AppBox', {
    staticClass: "col-12 col-md-4 col-sm-6",
    attrs: {
      "header": "Wentylacja"
    }
  }, [_c('app-driver-value', {
    attrs: {
      "driver": "swiatlo",
      "param": "czerpnia"
    }
  }), _c('app-driver-value', {
    attrs: {
      "driver": "swiatlo",
      "param": "wyrzutnia"
    }
  }), _c('app-driver-value', {
    attrs: {
      "driver": "swiatlo",
      "param": "wylot"
    }
  }), _c('app-driver-value', {
    attrs: {
      "driver": "swiatlo",
      "param": "nawiew"
    }
  })], 1), _c('AppBox', {
    staticClass: "col-12 col-md-4 col-sm-6",
    attrs: {
      "header": "Ustawienia"
    }
  }, [_c('FunctionBtn', {
    staticClass: "my-1 mx-auto",
    attrs: {
      "driver": "swiatlo",
      "func": "czasreku",
      "autoload": true,
      "size": "sm"
    }
  }), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_vm._v("Wyłącz wentylację")]), _c('SwitchBtn', {
    attrs: {
      "driver": "swiatlo",
      "pin": 47,
      "history-key": "w2_47",
      "reversed": ""
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('h4', {
    staticClass: "text-center my-4"
  }, [_vm._v("Wentylacja")])]);

}]

export { render, staticRenderFns }