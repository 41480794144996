<template>
  <div class="container px-0">
    <header>
      <h4 class="text-center my-4">Wentylacja</h4>
    </header>

    <div class="row">
      <SwitchBox
        driver="swiatlo"
        pin="49"
        history-key="w1_49"
        reversed
        class="col-12 col-md-4 col-sm-6"
      />

      <AppBox header="Wentylacja" class="col-12 col-md-4 col-sm-6">
        <app-driver-value driver="swiatlo" param="czerpnia" />
        <app-driver-value driver="swiatlo" param="wyrzutnia" />
        <app-driver-value driver="swiatlo" param="wylot" />
        <app-driver-value driver="swiatlo" param="nawiew" />
      </AppBox>

      <AppBox header="Ustawienia" class="col-12 col-md-4 col-sm-6 ">
        <FunctionBtn
          driver="swiatlo"
          func="czasreku"
          :autoload="true"
          size="sm"
          class="my-1 mx-auto"
        />
        <hr />

        <div class="d-flex justify-content-between">
          <div>Wyłącz wentylację</div>
          <SwitchBtn driver="swiatlo" :pin="47" history-key="w2_47" reversed />
        </div>
      </AppBox>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SwitchBox from '@/components/SwitchBox'
import SwitchBtn from '@/components/helpers/switch-btn'

export default {
  name: 'VentilationView',
  components: {
    SwitchBox,
    SwitchBtn
  },
  computed: {}
}
</script>
